/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  HomeIcon,
  FingerPrintIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  CollectionIcon,
  CogIcon,
  SupportIcon,
  PresentationChartBarIcon,
  XIcon,
} from '@heroicons/react/outline';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ChevronDownIcon } from '@heroicons/react/solid';
import header_img from "../img/nico_img.png";
import vector_img from "../img/Vector.png";
import { socialLinks } from "../data/socialLinks";  
import { FaEnvelope } from "react-icons/fa";
import { Fade } from "react-reveal";


const features = [
  {
    name: 'Home',
    href: '#',
    description: 'Get a better understanding of where your traffic is coming from.',
    icon: HomeIcon,
  },
  {
    name: 'About Me',
    href: '#aboutme',
    description: 'Speak directly to your customers in a more meaningful way.',
    icon: FingerPrintIcon,
  },
  { 
    name: 'Skills', 
    href: '#skills', 
    description: "Your customers' data will be safe and secure.", 
    icon: CogIcon},
  {
    name: 'Stats',
    href: '#stats',
    description: "Connect with third-party tools that you're already using.",
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Projects',
    href: '#projects',
    description: 'Build strategic funnels that will drive your customers to convert',
    icon: CollectionIcon,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: CogIcon },
]
const menuButtons = [
  { anchor: "#aboutme"},
  { anchor: "#skills"},
  { anchor: "#stats"},
  { anchor: "#projects"},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Banner() {
  return (
    
    <div className="relative sm:h-max font-sans">

      <Popover className="relative ">
        <div className="mx-auto z-20">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1 w-48">
              <a className="w-48"href="#">
                <span className="sr-only w-48">Workflow</span>
                <p className="h-auto w-48 sm:h-auto font-bold text-2xl">
                    Nicolai Herforth
                </p>
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <AnchorLink href="#" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110 ">
                Home
              </AnchorLink>
              <AnchorLink href="#aboutme" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                About Me
              </AnchorLink>
              <AnchorLink href="#skills" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Skills
              </AnchorLink>
              <AnchorLink href="#stats" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Stats
              </AnchorLink>
              <AnchorLink href="#projects" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Projects
              </AnchorLink>

              
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="https://drive.google.com/uc?export=download&id=1tr8gdMCz-Svaj2ZGS_veAEBeDuM3WW16"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-6 py-3 rounded-md shadow-sm text-base font-medium text-blue-600 hover:text-white bg-white-600 transition border border-blue-600 hover:bg-buttonblue hover:text-white"
              >
                Download CV
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            
            className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg  ring-opacity-5 bg-white divide-y-2 divide-gray-50 z-20">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                <div className="flex justify-start lg:w-0 lg:flex-1 w-48">
                  <a className="w-48"href="#">
                    <span className="sr-only w-48">Workflow</span>
                    <p className="h-auto w-48 sm:h-auto font-bold text-2xl">
                        Nicolai Herforth
                    </p>
                  </a>
                </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                <div className="mt-6">
                  <nav className="grid gap-y-8">                  
                    {features.map((item) => (
                      <AnchorLink
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon className="flex-shrink-0 h-6 w-6 text-blue-600" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                      </AnchorLink>
                    ))}
                  </nav>
                </div>

              </div>
              
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>


      <main className="lg:relative h-full z-10 flex flex-col">
        <div className="mx-auto w-full pt-16 pb-10 sm:pb-20 text-left">
          <div className="lg:w-4/5 xl:pr-16">
            <div className="flex items-center bg-blue-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <img alt='' src={vector_img} className="w-5 h-5 mr-2"/>
              <p className="font-semibold text-sm">DATA SCIENTIST</p>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 max-w-lg md:max-w-xl sm:text-5xl md:text-5xl  lg:text-6xl sm:mb-10">
              <span className="block xl:inline tracking-wide">I am experienced with advanced analytics, visualisation & ML</span>
            </h1>
            <p className="mt-3 max-w-full text-lg text-gray-800 md:mt-5 md:max-w-md  lg:mt-10 lg:mb-10 lg:text-xl">
            My experience ranges from deep analytical work to working directly with clients from a wide range of businesses.
            </p>
            <div className="mt-10 sm:flex justify-start">
              <div className="rounded-md">
                <AnchorLink
                  href="#aboutme"
                  className="flex px-3 py-2  w-max font-medium items-center justify-center border border-transparent rounded-lg text-white bg-buttonblue  transition hover:bg-blue-700 md:py-3 md:text-lg md:px-6 sm:px-5 sm:py-3"
                >
                  See More
                </AnchorLink>
              </div>
            </div>

            <div className="flex justify-start mt-20 flex-col lg:flex-row lg:justify-start">
              <p className="mr-6 text-base">Get me on</p>
              <div className="flex  mt-5 lg:mt-0">
                {socialLinks.map(item => (
                  <a
                    key={item.id}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="mr-6 text-2xl text-gray-400 lg:text-2xl lg:mr-6"
                    >
                      {item.icon}
                    </a>
                ))}
                <a href="mailto:nicolaiherforth@gmail.com" className="text-2xl text-gray-400 lg:text-2xl">
                    <FaEnvelope />
                </a>
              </div>
            </div>

        </div>
          
        </div>

          <div className="flex items-center -z-10 justify-center relative w-full h-72 sm:h-96 sm:block sm:absolute sm:inset-y-0 sm:right-0 sm:w-1/3 sm:h-full">
            <img
              className="absolute h-full max-w-sm sm:bottom-0  sm:w-auto sm:h-2/5 md:h-3/6 lg:block lg:bottom-20 lg:h-3/5 -z-10"
              src={header_img}
              alt=""
            />
            <div className="absolute bg-blue-100 h-52 bottom-0 w-52 rounded-t-full -z-20  sm:h-60 sm:w-60 sm:bottom-0 md:block md:h-72 md:w-72 lg:w-80 lg:h-80 lg:bottom-20"></div>
          </div>

      </main>

    </div>
  )
}
