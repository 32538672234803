import axios from "axios";


const getLinesPerRepo = async (repo) => {
    const headers = {"Authorization": "token " + process.env.REACT_APP_GIT_API}

    const {data} = await axios.get(
        repo.languages_url,{params:{}, headers: headers}
    );
    // If you want to filter out any type of language, add it to the blacklist here
    const blacklisted = ["Jupyter Notebook"]
    blacklisted.forEach(v => {
        delete data[v]
    })
    return {name: repo.name, lines: Object.values(data).reduce((p, c) => p + c, 0)}
}
export const getLinesOfCodePerRepo = async (repos) => {
    const promises = await repos.map(r => getLinesPerRepo(r))

    return await Promise.all(promises)
}
