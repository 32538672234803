/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import DBM from "../img/DBM.svg";
import DV from "../img/DV.svg";
import ML from "../img/ML.svg";
import NLP from "../img/NLP.svg";
import ANA from "../img/ANA.svg";

import { ChevronDownIcon } from '@heroicons/react/solid';
import header_img from "../img/nico_img.jpg";
import vector_img from "../img/Vector.png";
import { socialLinks } from "../data/socialLinks";  
import { FaEnvelope } from "react-icons/fa";
import { Fade } from "react-reveal";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const expertise = [
  {
    name: 'General Machine Learning',
    icon: ML,
  },
  {
    name: 'Natural Language Processing',
    icon: NLP,
  },
  {
    name: 'Data Visualisation ',
    icon: DV,
  },
  {
    name: 'Database Management',
    icon: DBM,
  },
  {
    name: 'Regular Data Analysis',
    icon: ANA,
  },
]
export default function AboutMe() {
  return (
    <div className="relative bg-white h-max font-sans" id="aboutme">
            <main className="lg:relative h-full ">
        <div className="mx-auto w-full  pb-20 text-left py-5 lg:text-left z-20">
          <div className="lg:w-4/5 xl:pr-16">
          <Fade left>
            <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <p className="font-normal text-sm">ABOUT ME</p>
            </div>
          </Fade>
          <Fade left>
            <h1 className="text-4xl font-bold  text-gray-900 max-w-xl  lg:m-0 lg:px-0 lg:max-w-4xl sm:text-5xl  sm:mb-10">
              <span className="block xl:inline tracking-wide">A full stack Data Scientist experienced in modeling and working with various kinds of data</span>{' '}
              {/* <span className="block xl:inline tracking-wide leading-snug">advanced analytics,<br></br></span>{' '}
              <span className="block xl:inline tracking-wide">visualisation & ML</span> */}
            </h1>
            <p className="mt-3 mb-5 max-w-full text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl  lg:m-0 lg:mt-10 lg:mb-10 sm:max-w-3xl ">
              My main areas of expertise includes:
            </p>
          </Fade>
            </div>
            <div className="max-w-full">
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
              <Fade bottom>
                {expertise.map((item) => (
                  <div className="transition transform duration-200 flex flex-col items-center justify-evenly border-opacity-100 border border-gray-200 m-1 sm:m-3 rounded-md py-10 px-2 md:h-72">
                    <img src={item.icon} className="bg-gray-100 rounded-full p-2 mb-10 h-14 w-14 md:w-20 md:h-20 xl:w-24 xl:h-24" aria-hidden="true"/>
                    <p className="ml-3 h-4 mb-4 font-medium text-left text-gray-900 text-base md:text-xl">{item.name}</p>
                  </div>
                ))}
              </Fade>
              </div>
            </div>
        </div>
      </main>
    </div>
  )
}
