/* This example requires Tailwind CSS v2.0+ */


import { useState, useContext } from "react";
import { Fade } from "react-reveal";
import {getLinesOfCodePerRepo} from "../data/getLinesOfCodePerRepo";
import { GithubRepos } from "../App";
import { useEffect } from "react";
import { getLinesPerLang, options } from "../data/getLinesPerLang";
import Loading from "../components/Loading";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { getProjectsPerLanguage } from "../data/getProjectsPerLanguage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";



export default function Charts() {
  const [projectsNumber, setProjectsNumber] = useState(0);
  const [languageNum, setLanguageNum] = useState(0);
  const [linesNum, setLinesNum] = useState(0);
  const [langs, setLangs] = useState([]);
  const [projsPerLang, setProjsPerLang] = useState([]);
  const [data, setData] = useState(null);
  const blacklisted = ["NicolaiHerforth.github.io", "network-analysis"]


  // here we use the context to get the [repos] from the App component
  const repos = useContext(GithubRepos);

  async function updateTotalLines(repos) {
      const res = await getLinesOfCodePerRepo(repos)
      
      const lines =  res.reduce((p, {lines}) => p + lines, 0)

      setLinesNum(lines)
  }
  

  // THIS IS ALL TESTING

  // console.log('Testing start')
  // const testRepos = [
  //   {'language': 
  //     {//'Python': 1,
  //     'Jupyter Notebook': 1,
  //     //'Javascript': 1
  //     }
  //   },
  //     {'language': 
  //       {'Python': 1,
  //       //'Typescript': 1
  //     }
  //   }]


  // const someObj = {
  //   'Jupyter': 1
  // }
  // for (const [key, value] of Object.entries(someObj)) {
  //   console.log(`${key}: ${value}`);
  // }

  // testRepos.reduce( (accuObj, currObj) => {
  //   for (const [key, value] of Object.entries(someObj)) {
  //     ({key: accuObj[key] ? accuObj[key] +1: 1})
  //   }
  // })


  // console.log('Testing end')
  // console.log('')
  // Set `RestLink` with your endpoint
  // const restLink = new RestLink({ uri: repos[0].languages_url });

  // Setup your client
  // const client = new ApolloClient({
  //   cache: new InMemoryCache(),
  //   link: restLink
  // });


  // THIS IS TESTING END!

  
  //if the repos change, then we update the number of projects
  // and the number of languages
  
  

  const genData = async (repos) => {
    try {
      const data = await getLinesOfCodePerRepo(repos.filter(r => !blacklisted.includes(r.name)))
      setData(getLinesPerLang(data.slice(0, 5)));
    } catch (error) {
        console.log("We got error: " + error);
    }
  };

  const genRepoLangs = async (repos) => {
    const projLines = await getProjectsPerLanguage(repos);
    setProjsPerLang(projLines)
  };
  useEffect(() => {
    if(repos.length > 0) {
    setProjectsNumber(repos.length);
    const languages = new Set();
    repos.forEach(repo => languages.add(repo.language));
  
    const uniqueLang = [...languages].filter(el => el);
    setLangs(uniqueLang);
    setLanguageNum(uniqueLang.length);
    updateTotalLines(repos);
    genData(repos);
    genRepoLangs(repos);
    }
  }, [repos]);
  var delayed;




  if (repos.length <= 0) return <Loading />;
  return (
    <div className="relative h-max font-sans" id="stats">
      <main className="relative h- flex flex-col md:flex-row">
      <div className="mx-auto max-w-screen-2xl w-full pb-10 md:pb-20 text-left py-5 lg:text-left z-20">
        
          <div className="w-full">
          <Fade left>
            <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <p className="font-normal text-sm">STATISTICS</p>
            </div>
            <h1 className="text-4xl font-bold  text-gray-900 max-w-xl mb-10 lg:m-0 lg:mb-7 lg:px-0 lg:max-w-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl sm:mb-10">
            <span className="block xl:inline tracking-wide m-w-md">Visual Representations</span>
            </h1>
            {/* <p className="max-w-lg text-lg font-semibold text-gray-700 sm:text-lg lg:mb-10">
              Number of Projects worked Per Language
            </p> 
            <div> 
              text
              {projsPerLang.map((item) => ({
                item
              }))}
              </div> */}
            <div>

            </div>
            
            <p className="max-w-lg text-lg font-semibold text-gray-700 mb-16 sm:text-lg ">
              Lines of Code in Recent Projects
            </p>
            </Fade>
            <Fade bottom>
            <div className="border rounded-md p-5 md:p-10">
              <div className="w-full mx-auto h-max block bo sm:hidden">
                {data && <Bar  data={data} 
                  options={{
                    responsive: true,
                    aspectRatio: 1,
                    layout: {
                      padding:{
                        top: 0
                      },
                      
                    },
                    plugins:{
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        xAxes: {
                            ticks: {
                                display:true,
                            },
                            grid: {
                              display:false
                          }
                        },
                        yAxes: {
                          grid: {
                            display:true,
                            color: '#F3F5F9'
                        }
                        },
                    },
                    title:{
                        display: false,
                    },
                    animation: {
                        onComplete: () => {
                          delayed = true;
                        },
                        delay: (context) => {
                          let delay = 0;
                          if (context.type === 'data' && context.mode === 'default' && !delayed) {
                            delay = context.dataIndex * 200 + context.datasetIndex * 100;
                          }
                          return delay;
                        },
                    }
                    
                  }}
                />}
              </div>
              <div className="w-full mx-auto h-max hidden sm:block md:hidden">
                {data && <Bar  data={data} 
                  options={{
                    responsive: true,
                    aspectRatio: 2,
                    layout: {
                      padding:{
                        top: 0
                      },
                      
                    },
                    plugins:{
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        xAxes: {
                            ticks: {
                                display:true,
                            },
                            grid: {
                              display:false
                          }
                        },
                        yAxes: {
                          grid: {
                            display:true,
                            color: '#F3F5F9'
                        }
                        },
                    },
                    title:{
                        display: false,
                    },
                    animation: {
                        onComplete: () => {
                          delayed = true;
                        },
                        delay: (context) => {
                          let delay = 0;
                          if (context.type === 'data' && context.mode === 'default' && !delayed) {
                            delay = context.dataIndex * 200 + context.datasetIndex * 100;
                          }
                          return delay;
                        },
                    }
                    
                  }}
                />}
              </div>
            
            <div className="w-full mx-auto h-max hidden md:block">
              {data && <Bar  data={data} 
                options={{
                  responsive: true,
                  aspectRatio: 3,
                  layout: {
                    padding:{
                      top: 0
                    },
                    
                  },
                  plugins:{
                      legend: {
                          display: false
                      }
                  },
                  scales: {
                      xAxes: {
                          ticks: {
                              display:true,
                          },
                          grid: {
                            display:false
                        }
                      },
                      yAxes: {
                        grid: {
                          display:true,
                          color: '#F3F5F9'
                      }
                      },
                  },
                  title:{
                      display: false,
                  },
                  animation: {
                      onComplete: () => {
                        delayed = true;
                      },
                      delay: (context) => {
                        let delay = 0;
                        if (context.type === 'data' && context.mode === 'default' && !delayed) {
                          delay = context.dataIndex * 200 + context.datasetIndex * 100;
                        }
                        return delay;
                      },
                  }
                  
                }}
              />}
            </div>
            </div>
            </Fade>
          </div>
        </div>
      </main>
  </div>
  )
}
