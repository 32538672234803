import { FaLinkedin, FaGithub, FaMediumM } from "react-icons/fa";

export const socialLinks = [
    {
        id: 1,
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/nicolai-herforth/",
        icon: <FaLinkedin />
    },
    {
        id: 2,
        name: "Github",
        url: "https://github.com/NicolaiHerforth",
        icon: <FaGithub />
    },
    {
        id: 3,
        name: "Medium",
        url: "https://nicolaiherforth.medium.com/",
        icon: <FaMediumM />
    },
]