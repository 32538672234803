import Banner from "./sections/Banner.js";
import AboutMe from "./sections/AboutMe.js";
import Stats from "./sections/Stats.js";
import Skills from "./sections/Skills.js";
import Charts from "./sections/Charts.js";
import Cases from "./sections/Cases";
import Projects from "./sections/Projects";
import Footer from "./sections/Footer";
import './App.css';
import { createContext, useEffect, useState } from "react";
import axios from "axios";


const blacklisted = []
export const GithubRepos = createContext();


function App() {
  const [repos, setRepos] = useState([]);
  const [allLangs, setAllLangs] = useState([]);
  const [data, setData] = useState({});
  const headers = {"Authorization": "token " + process.env.REACT_APP_GIT_API}
  async function getProjects() {

      try {
          const { data } = await axios.get(`https://api.github.com/users/NicolaiHerforth/repos?per_page=100&sort=created`,{params:{}, headers: headers}
          );
          setRepos(data.filter(r => !blacklisted.includes(r.name)));
      } catch (error) {
          console.log("Error:", error);
      }
  }

  //this is a react hook that is used to fetch data from github, this runs once the app is loaded
  useEffect(() => {
      getProjects();
  }, []);

  
  
  return (
    <GithubRepos.Provider value={repos}>
      <main className='font-sans  max-w-screen-xl mx-auto px-5 sm:px-10'>
        
        <Banner />
        <AboutMe />
        <Skills />
        <Stats />
        <Charts />
        <Cases />
        <Projects />
        <Footer />
      </main>
    </GithubRepos.Provider>
    
  );
}

export default App;
