import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";

const Loading = () => {
    return (
        <section style={{ padding: "2rem 0" }}>
            <Grid container justify="center">
                <CircularProgress color="secondary" />
            </Grid>
        </section>
    );
};

export default Loading;
