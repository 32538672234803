/* This example requires Tailwind CSS v2.0+ */
import { Fade } from "react-reveal";
import case1 from "../img/case1.jpg"
import case2 from "../img/case2.jpg"

const allCases = [
  {
    title: 'Conversion Prediction',
    date: '01 December 2021',
    language: 'Python',
    description: 'An enterprise client wanted to optimise their marketing ROI. We presented a reversed churn model which predicts a probability of a customer converting to the clients loyalty program to optimise marketing segmentation. The segment proved to have more than 300% higher conversion rate.',
    href: '#projects',
    flexDir: 'flex flex-col md:flex-row space-between',
    imgPos: 'flex flex-col items-end w-full md:w-1/2',
    img: case1,

  },
  {
    title: 'Advanced CJA Dashboarding',
    date: '01 December 2021',
    language: 'Microsoft Power BI',
    description: 'Understanding the customer journey from the customer seeing an ad all the way to a conversion provides deep insights in customer satisfaction and behaviour. By leveraging media platform data, behavioural data and CRM data, the client got a state-of-the-art Power BI dashboard with a website-like user experience.',
    href: '#projects',
    flexDir: 'flex flex-col md:flex-row-reverse space-between',
    imgPos: 'flex flex-col items-start w-full md:w-1/2',
    img: case2,
  }
]




export default function Cases() {
  

  
  return (
    <div className="relative h-max w-full font-sans mb-0" id="projects">

      {allCases.map(item => (
        <Fade right>
        <div className='mb-24 flex flex-col justify-center'>
          <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <p className="font-normal text-sm">PROJECTS</p>
          </div>
          <div className={item.flexDir}>
            <div className='w-full mb-14 md:mb-0 md:w-1/2'>
              <h1 className="text-4xl font-bold  text-gray-900 max-w-xl mb-4 lg:px-0 sm:text-5xl sm:mb-7">{item.title}</h1>
              <div className='flex font-bold mb-7 text-sm'>
                <p className='mr-7'>{item.language}</p>
                <p>{item.date}</p>
              </div>
              <p className="max-w-lg mb-14 text-sm text-gray-500 leading-6">
                {item.description}
              </p>
              <a href={item.href} className="bg-blue-100 transition text-blue-600 font-medium p-4 rounded-lg hover:bg-buttonblue hover:text-white">
                Case study TBA
              </a>
            </div>
            <div className={item.imgPos}>
              <div className="bg-blue-100 md:mb-4  xl:-mb-4 h-52 w-52 rounded-t-full -z-20 sm:h-60 sm:w-60 sm:bottom-0  md:h-72 md:w-72 lg:w-80 lg:h-80 "></div>
              <img src={item.img} className='-mt-64 transform scale-75 md:scale-95 lg:scale-75 md:-mt-64 lg:-mt-80' />
            </div>
          </div>
            
        </div>
        </Fade>
      ))}
    </div>
  )
}
