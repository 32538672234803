/* This example requires Tailwind CSS v2.0+ */

import { useState, useContext } from "react";
import { GithubRepos } from "../App";
import { useEffect } from "react";
import Loading from "../components/Loading";
import ProjectCard from "../components/ProjectCard";
import { Fade } from "react-reveal";

export default function Projects() {
  // we use  masonry package to render the projects
  // react-responsive-masonry package link: https://www.npmjs.com/package/react-responsive-masonry

  const repos = useContext(GithubRepos);
  const [latestSix, setLatestSix] = useState([]);
  const blacklisted = ['NicolaiHerforth.github.io']
  // this just makes sure we render the first 6 projects not all projects from github.
  useEffect(() => {
    if(repos.length > 0) {
      setLatestSix(repos.filter(r => !blacklisted.includes(r.name)).slice(0, 6));
    }
  }, [repos]);

  // if the data is not loaded yet, we render a loading component
  if (latestSix.length <= 0) return <Loading />;
  return (
    <div className="relative h-max font-sans flex flex-col justify-center align-center mb-32 py-5" id="stats">
      <Fade left>
        <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
          <p className="font-normal text-sm">PROJECTS</p>
        </div>
        <h1 className="text-4xl font-bold  text-gray-900 max-w-xl mb-10 lg:m-0 lg:mb-7 lg:px-0 lg:max-w-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl sm:mb-10">
          <span className="block xl:inline tracking-wide m-w-md">My Projects</span>
        </h1>
        <p className="max-w-lg text-lg text-gray-800 sm:text-lg mb-10">
          Below is a list of my latest projects on GitHub. Feel free to browse and explore.
        </p>
      </Fade>
      <div className="flex w-full grid grid-cols-1  justify-items-center md:grid-cols-2 lg:grid-cols-3">
        {latestSix.map(repo => (
          <ProjectCard key={repo.id} project={repo} className='mx-auto' />          
        ))}
        
      </div>
      <Fade bottom>
        <a href="https://github.com/NicolaiHerforth" target="_blank" className="self-center bg-buttonblue text-white font-medium rounded-lg px-7 py-4 transition hover:bg-blue-700">
          View all
        </a>
      </Fade>
    </div>
  )
}
