import axios from "axios";


const getProjPerLang = async (repo) => {
    
  const headers = {"Authorization": "token " + process.env.REACT_APP_GIT_API}

  const {data} = await axios.get(
      repo.languages_url,{params:{}, headers: headers}
  );

  // If you want to filter out any type of language, add it to the blacklist here
  const blacklisted = []//["Jupyter Notebook", 'Rich Text Format']
  blacklisted.forEach(v => {
      delete data[v]
  })
  const testMap = {};
  Object.keys(data).forEach(language => {
    testMap[language] = 1
  })

  return testMap
};

export const getProjectsPerLanguage = async (repos) => {
  // const promises = await repos.map(r => getProjPerLang(r))

  const allLangs = []
  const promises = await repos.map( repo => {
      getProjPerLang(repo).then( langMap => {
        for(var i in langMap) {

          allLangs[i]=typeof allLangs[i]!=='undefined'?allLangs[i]+1:1;
          }
      })
      
      })
  

  return allLangs
}
