/* This example requires Tailwind CSS v2.0+ */

import { useState, useContext } from "react";
import {getLinesOfCodePerRepo} from "../data/getLinesOfCodePerRepo";
import { GithubRepos } from "../App";
import { Fade } from "react-reveal";
import { useEffect } from "react";
import CountUp from "react-countup";





const blacklisted = ['NicolaiHerforth.github.io']

export default function Stats() {
  const [projectsNumber, setProjectsNumber] = useState(0);
  const [languageNum, setLanguageNum] = useState(0);
  const [linesNum, setLinesNum] = useState(0);
  const [langs, setLangs] = useState([]);



  // here we use the context to get the [repos] from the App component
  const repos = useContext(GithubRepos);

  async function updateTotalLines(repos) {
      const res = await getLinesOfCodePerRepo(repos)
      const lines =  res.reduce((p, {lines}) => p + lines, 0)

      setLinesNum(lines)
  }



  //if the repos change, then we update the number of projects
  // and the number of languages
  useEffect(() => {
    if(repos.length > 0 ){
      setProjectsNumber(repos.length);
      const languages = new Set();
      repos.forEach(repo => languages.add(repo.language));
      const uniqueLang = [...languages].filter(el => el);
      setLangs(uniqueLang);
      setLanguageNum(uniqueLang.length);
      updateTotalLines(repos.filter(r => !blacklisted.includes(r.name)))
    }
  }, [repos]);

  
  return (
    <div className="relative h-max font-sans" id="stats">
      <main className="relative h- flex flex-col md:flex-row">
        <div className="mx-auto max-w-screen-2xl w-full pb-10 md:pb-20 text-left py-5 lg:text-left z-20">
          <Fade left cascade style='opacity:0.5'>
            <div className=" lg:w-4/5 xl:pr-16">
            
              <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
                <p className="font-normal text-sm">STATISTICS</p>
              </div>
              <h1 className="text-4xl font-bold  text-gray-900 max-w-xl mb-10 lg:m-0 lg:mb-7 lg:px-0 lg:max-w-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl sm:mb-10">
              <span className="block xl:inline tracking-wide m-w-md">Stats of my working progress</span>
              </h1>
              <p className="max-w-lg text-lg text-gray-800 sm:text-lg lg:mb-10">
                My GitHub currently has <b>{projectsNumber} projects </b> so far. From those there are <b> {languageNum} programming languages </b> and in total through all the projects there are roughly <b> {linesNum.toLocaleString('de-DE')} lines of code. </b>
              </p>
            
            </div>
          </Fade>
        </div>
        
        <div className="max-w-sm w-full ml-auto text-white md:mt-20">

            <div className="bg-bigbubble relative opacity-90 h-56 w-56 md:h-64 md:w-64 rounded-full flex flex-col justify-center items-center ml-auto z-20">
              <h1 className="text-3xl font-bold lg:text-4xl">
                <CountUp
                    end= {linesNum}
                    duration={3}
                    separator="."
                />
                </h1> 
            <h3 className="text-sm">Lines of Code</h3>
            </div>


            <div className="bg-medbubble relative opacity-90 h-44 w-44 rounded-full flex flex-col justify-center items-center -mt-44 md:mr-56 -z-10">
              <h1 className="text-3xl font-bold lg:text-4xl">
                <CountUp
                    end={languageNum}
                    duration={1}
                    separator="."
                />
              </h1> 
              <h3 className="text-sm">Languages</h3>
            </div>


            <div className="bg-smallbubble relative opacity-90 h-36 w-36 rounded-full flex flex-col justify-center items-center -mt-12 md:-mt-12 md:mr-36 mx-auto -z-10">
              <h1 className="text-3xl font-bold lg:text-4xl">
                <CountUp
                    end={projectsNumber}
                    duration={1}
                    separator="."
                />
                </h1> 
              <h3 className="text-sm">Projects</h3>
            </div>

        </div>
      </main>
    </div>
  )
}
