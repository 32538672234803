
// const rand = () => Math.floor(Math.random() * 255);

export const getLinesPerLang = (repoLangs) => {
    let sum = 0
    const data = {
        labels: [...(repoLangs || []).map(({name}) => name)],
        datasets: [
            {
                type: 'bar',
                label: 'Lines of Code',

                hoverBorderWidth: 1,
                hoverBorderColor: 'gray',
                data: [...(repoLangs || []).map(({lines}) => lines), sum],
                borderWidth: 1,
                borderRadius: 10,
                backgroundColor: [
                    '#00BE82',
                    '#FE7831',
                    '#1F2233',
                    '#335AFF',
                    '#DC143C'
                ]
            },
        ],
    }

    return data
};

export const options = {
    scales: {
        categroy: [
            {ticks: {
                beginAtZero: true,
            }}
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};