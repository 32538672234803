/* This example requires Tailwind CSS v2.0+ */

import { Card } from "@material-ui/core";
import { BsCode, BsArrowRight, BsCalendar } from "react-icons/bs";
import { Fade } from "react-reveal";
import {FaArrowRight, FaCalendarAlt} from "react-icons/fa";
import {IoCalendarSharp} from "react-icons/io5"

const ProjectCard = ({ project }) => {

    return (
        // this is the card component for a project
        <Fade bottom>
            <div className="flex flex-col max-w-xs  w-80 shadow-xl p-8 rounded-md mx-auto h-cardheight max-h-cardheight border border-gray-100 mb-12 md:mx-0 ">
                    <h3 className="text-2xl mb-3">{project.name.replace(/[^a-zA-Z ]/g, ' ').split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}</h3>
                    <div className="flex text-sm justify-start mb-3 text-gray-700">
                        {project.language && (
                            <div className="flex items-center bg-gray-100 w-max h-7 mr-3 px-3 rounded-lg text-xs">
                                <h4 className="">
                                    {project.language.split(" ")[0]}
                                </h4>
                            </div>
                        )}
                        <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg text-xs">
                            <h4 className="flex">
                                <IoCalendarSharp className="my-auto"/> &nbsp;{" "}
                                {new Date(project.created_at).toDateString()}
                            </h4>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between h-full'>
                        <p className="text-gray-500 overflow-ellipsis text-sm leading-6">
                            {project.description && project.name && project.description.substring(0, 210)+"..." || project.name.substring(0, 7)}
                        </p>
                        <a className="flex align-center font-medium w-max transition transform hover:scale-110" href={project.html_url}
                        target="_blank"
                        rel="noreferrer"
                        >
                            <p className="text-blue-600">View Details</p> &nbsp; <FaArrowRight className="my-auto text-blue-600"/>
                        </a>
                    </div>
            </div>
        </Fade>
    );
};

export default ProjectCard;
