/* This example requires Tailwind CSS v2.0+ */


import { FaPython} from 'react-icons/fa';
import { 
  SiDjango, 
  SiHtml5, 
  SiCss3, 
  SiTensorflow,
  SiKeras,
  SiMicrosoftazure,
  SiPowerbi
  } from 'react-icons/si';
import { AiOutlineConsoleSql} from 'react-icons/ai';
import {CgCloud} from "react-icons/cg";
import { Fade } from "react-reveal";




const expertise = [
  {
    name: 'Python',
    icon: <FaPython />,
  },
  {
    name: 'Django',
    icon: <SiDjango />,
  },
  {
    name: 'SQL',
    icon: <AiOutlineConsoleSql />,
  },
  {
    name: 'HTML',
    icon: <SiHtml5 />,
  },
  {
    name: 'CSS',
    icon: <SiCss3 />,
  },
  {
    name: 'TensorFlow',
    icon: <SiTensorflow />,
  },
  {
    name: 'Keras',
    icon: <SiKeras />,
  },
  {
    name: 'Google Cloud',
    icon: <CgCloud />,
  },
  {
    name: 'Microsoft Azure',
    icon: <SiMicrosoftazure />,
  },
  {
    name: 'Power BI',
    icon: <SiPowerbi />,
  },
  
]
export default function Stats() {
  return (
    <div className="relative bg-white h-max font-sans" id="skills">
      <main className="relative h-full">
        <div className="mx-auto w-full  pb-20 text-left  py-5 lg:text-left z-20">
          <div className=" lg:w-4/5 xl:pr-16">
          <Fade left>
            <div className="flex items-center bg-gray-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <p className="font-normal text-sm">MY SKILLS</p>
            </div>
          </Fade>
          <Fade left>
            <h1 className="text-4xl font-bold  text-gray-900 max-w-xl mb-10 lg:m-0 lg:mb-20 lg:px-0 lg:max-w-4xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl sm:mb-10">
              <span className="block xl:inline tracking-wide">I work in the fields of Data Science & advanced analytics</span>{' '}
            </h1>
            </Fade>
          </div>
          <div className="max-w-full">
            
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
              {expertise.map((item) => (
                <Fade bottom cascade>
                <div className="transition transform duration-200 flex flex-col items-center justify-evenly border-opacity-100 border border-gray-200 m-1 sm:m-3 rounded-md py-10 px-2 md:h-72">
                  <div className="flex justify-center items-center text-7xl text-gray-900 text-center bg-gray-100 rounded-full p-3 mb-10 h-14 w-14 md:text-5xl md:w-20 md:h-20 xl:w-24 xl:h-24" aria-hidden="true"> {item.icon} </div>
                    <p className=" h-4 mb-4 font-medium text-center text-gray-900 text-base md:text-xl">{item.name}</p>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
